import isBot from '~/lib/isBot.js'

export default function ({ localePath, $store, $device }, slug, query = {}) {
  const path = { name: 'rv-rental', params: { alias: slug } }

  // Prevent bots from indexing query params
  if (isBot($device.userAgent) || $device.isCrawler) {
    return localePath(path)
  }

  return localePath({ ...path, query: { ...query, rts: $store.state.me.rtSessionId } })
}

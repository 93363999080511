import { render, staticRenderFns } from "./rv.vue?vue&type=template&id=660b5adc&scoped=true"
import script from "./rv.vue?vue&type=script&lang=js"
export * from "./rv.vue?vue&type=script&lang=js"
import style0 from "./rv.vue?vue&type=style&index=0&id=660b5adc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660b5adc",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/form/dates.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fcard%2Frv.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./rv.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Fa: require('/home/vsts/work/1/s/components/fa.vue').default,CardRvTag: require('/home/vsts/work/1/s/components/card/rv-tag.vue').default})

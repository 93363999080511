import { CountryCodeToCurrencyCode } from '~/lib/enums'
import { COUNTRY_CURRENCIES, CONVERSION_RATES } from '~/constants/index'

export default function ({
  value,
  i18n,
  countryCode = 'CA',
  showCurrencyCode = false,
  round = false,
  isFinancialNegative = false,
  internationalPricing = false,
  preferredCurrency = null,
  convertToNegative = false
}) {
  if (typeof value !== 'number' || isNaN(value)) return value

  const lang = i18n.locale
  const locale = `${lang}-${countryCode}`
  const currencyCode = CountryCodeToCurrencyCode(countryCode)

  const isValueNegative = convertToNegative || value < 0

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: round ? 0 : 2,
    maximumFractionDigits: round ? 0 : 2
  })

  const amount = Math.abs(value)

  const displayMoney = ({ amount, currencyCode, showCurrencyCode }) => {
    const negativeSign = isValueNegative ? '-' : ''
    const currencyDisplay = showCurrencyCode ? ` ${currencyCode}` : ''
    const moneyDisplay = i18n.t('common.moneyDisplay', { amount: formatter.format(amount) })
    const openParenthesis = isFinancialNegative ? '(' : ''
    const closeParenthesis = isFinancialNegative ? ')' : ''

    return `${negativeSign}${openParenthesis}${moneyDisplay}${currencyDisplay}${closeParenthesis}`
  }

  if (internationalPricing) {
    const convertedCurrencyCode = preferredCurrency || COUNTRY_CURRENCIES.US
    const conversionRate = CONVERSION_RATES[currencyCode][convertedCurrencyCode]
    if (conversionRate) {
      const convertedAmount = amount * conversionRate

      return displayMoney({
        amount: convertedAmount,
        currencyCode: convertedCurrencyCode,
        showCurrencyCode: preferredCurrency ? showCurrencyCode : false
      })
    }
  }

  return displayMoney({ amount, currencyCode, showCurrencyCode })
}

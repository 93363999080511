
import { RvTags } from '@/lib/enums'

const config = {
  [RvTags.Featured]: { label: 'featured' },
  [RvTags.DeliveryOnly]: { label: 'deliveryonly', icon: 'truck-fast' }
}

export default {
  name: 'RvCardTag',

  props: {
    tag: {
      type: String,
      required: true
    }
  },

  methods: {
    getLabel(tag) {
      if (!this.$te(config[tag]?.label)) return

      return this.$t(config[tag]?.label)
    },

    getIcon(tag) {
      if (!config[tag]?.icon) return

      return config[tag]?.icon
    }
  }
}


import { ImageResolution, RvTags } from '@/lib/enums'
import { ObserveVisibility } from 'vue-observe-visibility'
import absoluteRvUrl from '~/lib/absoluteRvUrl.js'
import rvUrl from '~/lib/rvUrl.js'
import {
  getOriginalNightlyRate,
  getRvMeasurementUnit,
  getSmartNightlyRate,
  getBadges,
  getTags,
  isDeliveryOnly,
  isInstantBook,
  isSuperhost
} from '~/lib/rvs'
import { searchParametersToSearchQuery } from '~/lib/search/parameters.js'
import { trackListingClicked, trackListingSetViewed } from '~/lib/tracking'
import { imageUrl } from '~/lib/useImageUrl'
import usePrice from '~/lib/usePrice'
import useRegionToIsoCode from '~/lib/useRegionToIsoCode'
import useRvType from '~/lib/useRvType'
import useToDistance from '~/lib/useToDistance'

export default {
  name: 'CardRv',

  directives: {
    ObserveVisibility
  },

  props: {
    /**
     * The actual index of the RV in the list. This is used to emit back to the
     * list in order to tell the list when to load the next set of RVs.
     */
    index: {
      type: Number,
      default: 0
    },

    rv: {
      type: Object,
      default: () => ({})
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    cta: {
      type: String,
      default: null
    },

    searchRequestId: {
      type: String,
      required: true
    },

    visibilityThreshold: {
      type: Number,
      default: 0.5
    },

    visibilityThrottle: {
      type: Number,
      default: 500
    },

    coords: {
      type: [Array, Boolean],
      default: false
    },

    destinationName: {
      type: [String, Boolean],
      default: false
    },

    variant: {
      type: String,
      default: 'gallery'
    },

    trackVisiblity: {
      type: Boolean,
      default: false
    },

    showFeatured: {
      type: Boolean,
      default: false
    },

    showDescription: {
      type: Boolean,
      default: false
    },

    /**
     * Determines wether or not a card should load when it scrolls into view
     * (default), or imediately (useful for SEO).
     */
    loadWhenVisible: {
      type: Boolean,
      default: true
    },

    pageSize: {
      type: Number,
      default: 10
    },

    listPageNumber: {
      type: Number,
      default: 1
    },

    dates: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      imageIndex: 0,

      /**
       * Tracks wether or not the card is scrolled into view.
       */
      isVisible: false
    }
  },

  computed: {
    RvTags() {
      return RvTags
    },

    datesText() {
      if (!this.dates) return

      const start = this.$moment(this.dates.start)
      const end = this.$moment(this.dates.end)

      const sameMonth = start.month() === end.month()

      const startDateFormat = this.$t(`dates.range.start.${sameMonth ? 'short' : 'long'}`)
      const endDateFormat = this.$t(`dates.range.end.${sameMonth ? 'short' : 'long'}`)

      return `${start.format(startDateFormat)} — ${end.format(endDateFormat)}`
    },

    /**
     * The card can be in 2 loading "states":
     * 1. The data is actually still loading from the API.
     * 2. The card hasn't scrolled into view yet (if enabled).
     */
    isStillLoading() {
      return this.isLoading || !this.isVisible
    },

    isFeatured() {
      return this.cta === 'featured'
    },

    showDistance() {
      return Boolean(
        (this.destinationName && this.destinationName !== this.rv.City) ||
          this.$search.parameters.location?.city?.long_name
      )
    },

    distanceUnit() {
      return getRvMeasurementUnit(this.rv)
    },

    distanceAway() {
      const lat = this.coords[0] || this.$search.parameters.location?.center?.lat
      const lng = this.coords[1] || this.$search.parameters.location?.center?.lng

      if (!(lat && lng)) {
        return null
      }

      return useToDistance({
        locale: this.$i18n,
        lat1: lat,
        lon1: lng,
        lat2: this.rv.Latitude,
        lon2: this.rv.Longitude,
        unit: this.distanceUnit,
        showUnit: true
      })
    },

    urlToListing() {
      const query = searchParametersToSearchQuery(this.$search.parameters)

      if (this.dates && !query.StartDate && !query.EndDate) {
        query.StartDate = this.dates.start
        query.EndDate = this.dates.end
      }
      return rvUrl(this, this.rv.AliasName, query)
    },

    photos() {
      if (!this.rv?.Photos) {
        return []
      }

      const photos = this.rv.Photos.map((photo) => ({
        path: photo.Path,
        alt: this.rv.RVName
      }))

      return photos
    },

    imageResolution() {
      return ImageResolution.MEDIUM
    },

    isWebviewLayout() {
      return this.$nuxt?.layoutName === 'webview'
    },

    isMobileDevice() {
      return this.$device.isMobile
    },

    openInSameWindow() {
      return this.isWebviewLayout || this.isMobileDevice
    },

    linkTarget() {
      if (!this.openInSameWindow) {
        return '_blank'
      }
      return null
    },

    showDistanceAway() {
      return this.cta !== 'map' && this.showDistance && this.distanceAway !== null
    },

    isSuperhost() {
      return isSuperhost(this.rv)
    },

    isInstantBook() {
      return isInstantBook(this.rv)
    },

    isDeliveryOnly() {
      return isDeliveryOnly(this.rv)
    },

    badges() {
      const badges = getBadges(this.rv)
      if (!badges) return

      return badges
    },

    badge() {
      return this.badges?.priority
    },

    tags() {
      return getTags(this.rv, this.isFeatured)
    },

    hasTags() {
      return Boolean(this.tags?.length)
    },

    shouldShowBadge() {
      return Boolean(this.badges)
    }
  },

  created() {
    /**
     * Determine wether or not we want to only show data when the card is
     * scrolled into view.
     */
    this.isVisible = !this.loadWhenVisible
  },

  methods: {
    useRvType,
    useRegionToIsoCode,
    usePrice(args) {
      return usePrice({
        ...args,
        i18n: this.$i18n,
        countryCode: this.rv.Country,
        round: true,
        preferredCurrency: this.$store.state.currency.currencyCode,
        internationalPricing: this.$useInternationalPricing()
      })
    },

    /**
     * Triggers when the card has scrolled into (or out of) view.
     */
    scrolledIntoView(visible) {
      /**
       * If we don't want to lazy load content, or when the card has scrolled
       * out of view, exit early.
       *
       * Otherwise fire the 'scrolled-into-view' event and track it locally.
       */
      if (!this.loadWhenVisible || !visible) return

      this.$emit('scrolled-into-view', this.index)

      this.isVisible = visible
    },

    onCarouselClick(e) {
      // Skip track at photos navigation
      if (e.target.closest('.glide__bullet') || e.target.closest('.glide__arrow')) {
        e.stopPropagation()
        e.preventDefault()
      }
    },

    updateImageIndex(index) {
      this.imageIndex = index
    },

    trackClick() {
      const me = this.$refs.card.$el
      const parent = me.parentNode

      const listIndex = [...parent.children].findIndex((el) => el === me)
      const position = listIndex + 1
      const columnCount = window.getComputedStyle(parent).getPropertyValue('grid-template-columns').split(' ').length
      const listRowNumber = Math.floor(listIndex / columnCount) + 1
      const listColumnNumber = (listIndex % columnCount) + 1

      trackListingClicked({
        cta: this.cta || this.getRouteBaseName(),
        distanceShown: this.showDistance,
        hasDiscount: this.rv?.DiscountPercent > 0 ?? undefined,
        hasFlexibleDates: undefined,
        isMapOpen: document.getElementsByClassName('map-wrapper').length > 0,
        isShortStay: this.rv.IsShortStay ?? false,
        isSuperhost: this.rv.IsSuperHostActive ?? false,
        listCardVariant: this.variant,
        listColumnNumber,
        listRowNumber,
        pageSource: this.getRouteBaseName(),
        photoPath: this.photos[this.imageIndex].path,
        photoPosition: this.imageIndex + 1,
        rv: {
          heroImage: imageUrl({
            $config: this.$config,
            path: this.rv.Photos[0].Path,
            resolution: ImageResolution.LARGE
          }),
          isFavourite: Boolean(this.$store.getters['favourites/isRvFavourited'](this.rv.Id)),
          ...this.rv
        },
        position,
        rvType: this.rv.RVType
      })
    },

    rvClicked() {
      if (this.isStillLoading) {
        return
      }

      this.trackClick()
      this.setRvDetailsPageReferrerAndCta()

      if (this.cta === 'map') {
        return
      }
    },

    visibilityChanged(visible) {
      if (!visible) return

      if (!this.trackVisiblity) {
        this.$emit('visible:rv', { rv: this.rv, cta: this.cta, requestId: this.searchRequestId })
        return
      }
      trackListingSetViewed({
        cta: this.cta,
        listingPageNumber: this.listPageNumber,
        listingPageSize: this.pageSize,
        searchResults: [
          {
            heroImage: imageUrl({
              $config: this.$config,
              path: this.rv.Photos[0].Path,
              resolution: ImageResolution.LARGE
            }),
            rvUrl: absoluteRvUrl(this, this.rv.AliasName),
            isFavourite: Boolean(this.$store.getters['favourites/isRvFavourited'](this.rv.Id)),
            nightlyRate: getOriginalNightlyRate(this.rv),
            smartNightlyRate: getSmartNightlyRate(this.rv),
            ...this.rv
          }
        ],
        pageSource: this.getRouteBaseName()
      })
    },

    setRvDetailsPageReferrerAndCta() {
      if (this.cta) {
        // Add rvId to the key, so it's unique to each RV.
        // Localstorage was used instead of sessionStorage, as sessionStorage didn't work when opening certain links in a new tab
        window.localStorage.setItem(`rvDetailsPageCta_${this.rv.Id}`, this.cta)
      }

      // Store the current route in localStorage. The RV Details page is opened in a new tab, so context does not contain the route
      window.localStorage.setItem(`rvDetailsPageCtaReferrer_${this.rv.Id}`, this.getRouteBaseName(this.$route) || '')
    },

    // eslint-disable-next-line no-unused-vars
    trackQueuedActions(photos) {
      /*
        This event is being commented out until we need it again
        Reference ticket: RVZ-12612
      */
      // if (this.rv && photos.length) {
      //   trackPhotoCarouselUpdateSuccessful({
      //       rvId: this.rv.Id,
      //       pageSource: this.getRouteBaseName(),
      //       photoListSize: this.photos.length,
      //       requestId: this.searchRequestId || undefined,
      //       actionType: this.$device.isDesktop ? 'click' : 'scroll',
      //       photos: photos
      //     })
      // }
    }
  }
}
